<template>
  <YLayoutBodyMenu :menu="[
      {title:'邀请海报',path:'/shop/system/poster-v2'},
      {title:'基础配置',path:'/shop/system/info'},
  ]">
    <router-view/>
    </YLayoutBodyMenu>
</template>

<script>
import YLayoutBodyMenu from "@/components/layouts/YLayoutBodyMenu";

export default {
  name: "index",
  components: {YLayoutBodyMenu},
}
</script>

<style scoped>

</style>